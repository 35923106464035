import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-take-back-the-web",
      "style": {
        "position": "relative"
      }
    }}>{`🌐 Take Back the Web`}<a parentName="h1" {...{
        "href": "#-take-back-the-web",
        "aria-label": " take back the web permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Why did the initial visions for the world wide web not work out quite as their inventors intended? It seems that it boils down to the incentives for building it. In some ways, you can consider the last three weeks to have been an extended consideration of how we might alter society's incentive structures using global, borderless, ownerless protocols for money.`}</p>
    <p>{`You've been led through how to think about a vastly interconnected world, and you have spent quality time critical concepts like `}<a parentName="p" {...{
        "href": "/learn/module-0/trust"
      }}>{`trust`}</a>{`, `}<a parentName="p" {...{
        "href": "/learn/module-1/value"
      }}>{`value`}</a>{`, `}<a parentName="p" {...{
        "href": "/learn/module-1/meaning"
      }}>{`meaning`}</a>{`, `}<a parentName="p" {...{
        "href": "/learn/module-2/money-speech"
      }}>{`money and speech`}</a>{`. It's time to apply these ideas to the ultimate problem: how to free the shared record of human knowledge from closed, rent-seeking corporations and extricate ourselves from an extractive attention economy. `}</p>
    <h2 {...{
      "id": "humble-cure-ations",
      "style": {
        "position": "relative"
      }
    }}>{`Humble Cure-ations`}<a parentName="h2" {...{
        "href": "#humble-cure-ations",
        "aria-label": "humble cure ations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Now that we understand the foundational concepts mentioned above, it's time to look a little
deeper into the human stack. We cannot hope to succeed where the giants on whose shoulders we
stand failed, if we do not understand our own intentions and what freedom really means to each
one of us.`}</p>
    <p>{`Incentives can be consciously engineered and secured with deterministic, auditable, and shared code. However, we still have to know `}<em parentName="p">{`why`}</em>{` we encode certain incentives and not others. This goes to the heart of what kind of reality you want to participate in, and what kinds of freedom you genuinely feel are worth speaking into being.`}</p>
    <p>{`While the crafted readings explore these abstract concepts, the curated material this week
grounds itself in a mix of history and cutting-edge thought to make an argument about what "taking back the web" means in a practical sense. We'll develop three core pillars, and then bring it back to Week 0, and our original emphasis on `}<strong parentName="p">{`humility`}</strong>{`. The three pillars are:`}</p>
    <Process mdxType="Process">
      <p>{`Augmenting our ability to think for ourselves`}</p>
      <p>{`Reclaiming our time `}</p>
      <p>{`Extending our ability to cooperate`}</p>
    </Process>
    <p>{`While the first two pillars point to internal or introspective work, it is critical that the third moves us back into entanglement with the world beyond ourselves. The materials in this module do not sum up to an argument for "sovereignty" in the sense so often used in crypto, nor do we ever make a case for "ownership" or "control" in a narrow, individualistic, or autonomous (i.e. separate) manner. `}<a parentName="p" {...{
        "href": "https://poetryarchive.org/poem/moment/"
      }}>{`Margaret Atwood explains why here`}</a>{`, and we will be building on her ideas throughout the remaining modules, as well as in our `}<a parentName="p" {...{
        "href": "https://sign.kernel.community"
      }}>{`shared, collaborative creations`}</a>{`.`}</p>
    <h2 {...{
      "id": "week-3-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 3 Firesides`}<a parentName="h2" {...{
        "href": "#week-3-firesides",
        "aria-label": "week 3 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Brewster Kahle & Mai Ishikawa Sutton - October 6, 2022`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/1dI2QeWXFKk?start=314" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Eva Beylin - October 21, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/a5UHCRkbBQ4?start=314" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Sam Williams - June 10, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/8ZDEc7uFi64?start=261" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Juan Benet - July 30, 2020`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/WjQ87t2s2y8?start=1257" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`David Vorick and Manasi Vora - Feb 4, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/zNx7_qYdsrE?start=217" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      